@keyframes float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
        animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
    }
    100% {
        transform: translateY(0);
    }
}

.float-animation {
    animation: float 3s infinite;
}
